import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../../components/layout/layout'
import FeaturedItems from '../../components/FeaturedItems'
import { BuiltIn, Investors, Team, WorkWithUs } from '../../components/about'

const About = props => {
  const { location, pageContext } = props
  return (
    <Layout location={location} pageContext={pageContext}>
      <FeaturedItems {...pageContext.coreValues} />
      <BuiltIn {...pageContext.builtInNyc} />
      <Team {...pageContext.team} />
      <WorkWithUs {...pageContext.workWithUs} />
      <Investors {...pageContext.investors} />
    </Layout>
  )
}

About.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object
}

export default About
