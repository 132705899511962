import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'gatsby'

import { SubHeading } from '../headings'
import styles from './WorkWithUs.module.scss'

const WorkWithUs = props => {
  const { currentOpenings } = props
  return (
    <section className={styles.container}>
      <div>
        <SubHeading className={styles.title}>We&apos;re Hiring</SubHeading>
        <ul className={styles.benefits}>
          <li>
            <span role="img" className={styles.emoji} aria-label="">
              👴🏻
            </span>{' '}
            401(k) plan
          </li>
          <li>
            <span role="img" className={styles.emoji} aria-label="">
              🏥
            </span>{' '}
            Premium health, dental & vision coverage
          </li>
          <li>
            <span role="img" className={styles.emoji} aria-label="">
              🚆
            </span>{' '}
            Commuter benefits
          </li>
          <li>
            <span role="img" className={styles.emoji} aria-label="">
              🍺
            </span>{' '}
            Beer and coffee on tap
          </li>
          <li>
            <span role="img" className={styles.emoji} aria-label="">
              📈
            </span>{' '}
            Equity incentives for all employees
          </li>
        </ul>
      </div>
      <div>
        <SubHeading className={styles.title}>Current Openings</SubHeading>
        <ul className={styles.openings}>
          {currentOpenings.map(({ id, title, url }) => (
            <li key={id}>
              <a href={url} target="_blank" rel="noopener noreferrer">{title}</a>
            </li>
          ))}
          <li className={styles.seeMore}>
            <a href="https://angel.co/company/rhombus/jobs" target="_blank" rel="noopener noreferrer">Browse All Openings…</a>
          </li>
        </ul>
      </div>
    </section>
  )
}

WorkWithUs.propTypes = {
  currentOpenings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string
    })
  ),
}

export default WorkWithUs
