import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { classNames } from '@utils'
import { Heading, SubHeading } from '../headings'
import styles from './BuiltIn.module.scss'

const BuiltIn = props => {
  const { text1, text2, image } = props
  const [visible, setVisibility] = useState(false)
  const inputEl = useRef(null)

  function handleScroll() {
    const { height, bottom } = inputEl.current.getBoundingClientRect()
    const WindowTopPosition = window.pageYOffset
    const offset = (bottom - height) | 0
    setVisibility(offset < WindowTopPosition)
  }

  function removeEvent() {
    if (window) {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => removeEvent()
  }, [])

  return (
    <section className={styles.container} ref={inputEl}>
      <div className={styles.text}>
        <Heading className={styles.heading1}>{text1}</Heading>
        <SubHeading className={styles.heading2}>{text2}</SubHeading>
      </div>
      <div
        className={
          classNames(
            styles.picture,
            visible && styles.visible,
            !visible && styles.invisible,
          )
        }
      >
        <img
          className={styles.image}
          src={image}
          alt=""
        />
      </div>
    </section>
  )
}

BuiltIn.propTypes = {
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
}

export default BuiltIn
