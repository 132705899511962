import React from 'react'
import PropTypes from 'prop-types'

import styles from './Team.module.scss'
import { Heading } from '../headings'

const Team = props => {
  const { title, members } = props
  return (
    <section className={styles.container}>
      <Heading>{title}</Heading>
      <div className={styles.team}>
        {members.map(({ name, position, picture, link }) => (
          <a key={name} href={link} target="_blank" rel="noopener noreferrer">
          <div className={styles.member}>
            <img
              src={`/images/team/${picture}`}
              className={styles.picture}
              alt={name}
            />
            <h3 className={styles.name}>{name}</h3>
            <em className={styles.position}>{position}</em>
          </div>
          </a>
        ))}
      </div>
    </section>
  )
}

Team.propTypes = {
  title: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      picture: PropTypes.string.isRequired
    })
  ),
}

export default Team
