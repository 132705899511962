import React from 'react'
import PropTypes from 'prop-types'

import { Heading } from '../headings'
import styles from './Investors.module.scss'

const Investors = props => {
  const { title, topInvestors } = props
  return (
    <section>
      <Heading className={styles.title}>{title}</Heading>
      <div className={styles.investors}>
        {topInvestors.map(({ name, logo }) => (
          <div key={logo}>
            <img src={logo} className={styles.logo} alt={name} title={name} />
          </div>
        ))}
      </div>
    </section>
  )
}

Investors.propTypes = {
  title: PropTypes.string.isRequired,
  topInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Investors
